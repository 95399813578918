import { Link } from "react-router-dom";

export default function Intro() {
    return (
        <>
            <Link to="/entry">
                <h3>click to continue</h3>
            </Link>
        </>
    );
}
